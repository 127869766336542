.custom_node {
  padding: 15px;
  text-align: center;
  border-radius: 15px;
  background-color: var(--yellow);
  width: 190px;
  min-height: 67px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000000;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 6px;

  &#selected {
    background-color: var(--blue);
    color: white;
  }

  &__options {
    background-color: transparent !important;
    width: fit-content !important;
    height: fit-content !important;

    & div {
      background-color: white;
    }
  }
}

.handle_top {
  visibility: hidden;
}

.handle_bottom {
  background: #F5EC10;
  border: 1px solid black;
  width: 7px;
  height: 7px;
  cursor: pointer;
  pointer-events: all;

  &.selected {
    background: var(--blue);
  }
}
