.zoom_box {
    display: flex;
    width: 144px;
    border-radius: 15px;
    border: 1px solid black;
    padding: 16px 0;
    &__button {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &__text {
      width: 40%;
      text-align: center;
    }
  }