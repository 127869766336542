.question_box {
  display: flex;
  gap: 14px;
  align-items: center;
  border: 1px solid #000000;
  border-radius: 15px;
  padding: 8px;
  padding-left: 33px;
  width: 100%;
  max-width: 556px;
  width: 100%;

  @media screen and (max-width: 768px){
    padding-left: 12px;
    gap: 5px;

    &__send {
      &__button {
        width: 30px;
        border-radius: 12px;
      }
    }
  }

  &__text {
    font-size: var(--paragraph-1);
    text-align: center;
    text-wrap: nowrap;
  }

  &__container {
    flex-grow: 1;
  }

  &__send {
    &__button {
      width: 37px;
      aspect-ratio: 1/1;
      background-color: var(--blue);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #000000;
      cursor: pointer;
    }
  }
}
