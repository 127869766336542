.workspace {
  width: 100%;
  height: 100%;
  &__content {
    height: calc(100% - 144px);
    position: relative;

    &__learn {
      height: calc(100%);
      width: 100%;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 5;
      &__text {
        font-size: var(--page-heading);
        font-weight: 700;
        text-align: center;
        color: var(--blue);
        max-width: 320px;
      }
      &__arrow {
        position: absolute;
        margin-top: 40px;
        top: 55%;
        & img {
          height: 145px;
        }
      }
    }

    &__map {
      height: 100%;
      width: 100%;
    }
  }

  &__bottom {
    height: 144px;
    width: 100%;
  }
}
