.nav_map {
  --middle-width: 300px;
  --middle-font-size: var(--section-heading);

  width: 100%;
  padding-top: 20px;
  display: flex;

  &::before {
    content: "";
    width: 100%;
    height: 160px;
    position: absolute;
    z-index: -1;
    top: -20px;
  }

  &__left {
    width: calc(50% - var(--middle-width) / 2);
  }
  &__middle {
    width: var(--middle-width);
    font-size: var(--middle-font-size);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__right {
    width: calc(50% - var(--middle-width) / 2);
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;

    &.small {
      padding-right: 16px;
      gap: 8px;
      & button {
        width: 35px;
        border-radius: 12px;
        & svg {
          width: 17px;
        }
      }
    }

    &__blue {
      border-radius: 15px;
      border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 13px 11px;
      padding-right: 17px;
      background-color: var(--blue);
      color: white;
      cursor: pointer;
    }
  }

  &__middle.hide {
    display: none;
  }

  &__right.stretch {
    width: calc(50% + var(--middle-width) / 2);
  }

  @media screen and (max-width: 768px) {
    --middle-width: 120px;
    --middle-font-size: var(--paragraph-2);
  }
}
