.confirm_button {
    background-color: var(--blue);
    padding: 7px 11px;
    padding-right: 31px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid black;
    display: flex;
    gap: 10px;
    align-items: center;

    &__icon {
        display: flex;
        align-items: center;
    }

    &__text {
        font-size: var(--paragraph-2);
        color: white;
    }
}