@import url("https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap");

@font-face {
  font-family: "NEURIAL GROTESK";
  font-weight: 700;
  src: url("./assets/fonts/NeurialGrotesk-Bold.otf");
}

@font-face {
  font-family: "NEURIAL GROTESK";
  font-weight: 500;
  src: url("./assets/fonts/NeurialGrotesk-Medium.otf");
}

@font-face {
  font-family: "NEURIAL GROTESK";
  src: url("./assets/fonts/NeurialGrotesk-Regular.otf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "NEURIAL GROTESK";
  font-weight: 400;
  font-size: var(--paragraph-1);
}

body,
html {
  height: 100%;
  width: 100%;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

:root {
  --paragraph-1: 15px;
  --paragraph-2: 12px;
  --section-heading: 20px;
  --page-heading: 30px;
  --landing-page-heading: 50px;
  --refresher-overview-heading: 25px;

  --blue: #0085ff;
  --yellow: #fff175;
  --blue-white: #e7f3ff;
  --grey-white: #f2f2f2;

  /* Tooltip */
  --rt-opacity: 1 !important;

  @media screen and (max-width: 768px) {
    --paragraph-1: 12px;
    --page-heading: 20px;
    --landing-page-heading: 24px;
    --section-heading: 14px;
  }
}

.cursive {
  font-family: "Just Another Hand", cursive;
  font-style: normal;
}

.tooltip {
  border-radius: 10px !important;
  font-size: 12px !important;
  z-index: 9999 !important;
}
