.options_dropdown {
  position: absolute;
  width: 222px;
  right: 100%;
  transform: translateX(50%);
  top: 65px;
  border-radius: 15px;
  border: 1px solid black;
  background: #fdfdfd;
  z-index: 9;
  padding: 0 8px;
  padding-top: 9px;
  &__item {
    display: flex;
    gap: 16px;
    padding: 12px 0px;
    padding-left: 20px;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }

    &.selected {
      border-radius: 10px;
      background-color: var(--yellow);
      border: 1px solid black;
    }

    &__text {
      font-size: var(--paragraph-1);
      display: flex;
      align-items: center;
    }
  }

  @media screen and (max-width: 1024px){
    right: 0;
    transform: none;
    top: 40px;

    &.options_dropdown_left {
      left: -130%;
    }

    &__item {
      padding: 9px 0;
      padding-left: 12px;
    }
  }
}
