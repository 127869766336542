.resource_card {
  width: 180px;
  cursor: pointer;

  &:hover {
    transform: scale(0.95);
  }

  &__thumbnail {
    width: 100%;

    & img {
      max-width: 100%;
    }
  }

  &__title {
    font-size: var(--paragraph-1);
    font-weight: 700;
  }
}
