.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__left {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    width: 33%;

    &__box {
      border: 1px solid black;
      border-radius: 15px;
      padding: 15px 20px;
    }
  }

  &__right {
    background-color: var(--yellow);
    padding: 15px 20px;
    border: 1px solid black;
    border-radius: 15px;
    &__spinner {
      border: 5px solid var(--blue);
      border-top: 5px solid white;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      animation: spin 1s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
