.side_panel_resources {
  position: relative;

  &__title {
    font-size: var(--section-heading);
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__resources {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}
