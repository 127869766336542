.home_page__main_section {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 150px;

  &__text {
    font-size: var(--section-heading);
    font-weight: 500;
    text-align: center;
    z-index: 10;
  }
  
  &__title {
    font-size: var(--landing-page-heading);
    font-weight: 700;
    color: var(--blue);
    text-align: center;
    margin-top: 14px;
    position: relative;
    z-index: 10;
    width: 65%;
  }

  &__input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &__box {
      width: calc(100% - 32px);
      max-width: 900px;
      margin-top: 36px;
    }
    &__loop {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      max-width: 100%;
      top: -10px;

      @media screen and (max-width: 500px) {
        top: 60%;
      }
    }
  }

  &__plus {
    position: absolute;
    left: 0;
    top: 0;
    width: 15%;
    min-width: 100px;

    
  }
  &__star {
    position: absolute;
    right: 0;
    top: 0;
    width: 12%;
    min-width: 100px;
  }
  @media screen and (max-width: 500px){

    &__plus{
      left: -10%;
    }

    &__star {
      left: 100%;
      transform: translateX(-70%);
    }
  }
}
