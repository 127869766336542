.api_key_input {
  display: flex;
  flex-direction: column;
  padding: 0 28px;
  gap: 16px;
  &__header {
    display: flex;
    justify-content: space-between;

    &__title {
      font-size: var(--paragraph-1);
      font-weight: 500;
    }

    &__help {
      & a {
        color: #525252;
        text-decoration: none;
      }
    }
  }

  &__input {
    border: 1px solid black;
    display: flex;
    padding: 15px 20px;
    border-radius: 11px;

    & input {
      width: 100%;
      height: 100%;
      font-size: var(--paragraph-1);
      border: none;
      resize: none;
      &:focus {
        outline: none;
      }
    }
  }

  &__save_button {
    & .confirm_button {
      justify-content: center;
    }
  }
}
