.map_card {
  position: relative;
  cursor: pointer;
  width: 263px;
  min-width: 263px;
  min-height: 227px;
  align-self: stretch;
  border-radius: 15px;
  border: 1px solid black;
  border-bottom: 5px solid black;
  padding: 13px;
  background-color: white;
  &:hover {
    scale: 0.99;
  }
  &__content {
    width: 100%;
    margin-top: 11px;
    &__title {
      font-size: var(--paragraph-1);
      font-weight: 500;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      &__button {
        overflow-y:visible;

        &:hover {
          .map_card__content__title__button__options {
            display: block;
          }
        }

        & img {
          cursor: pointer;

          &:hover {
            transform: scale(1.5);
          }

          &:active {
            transform: scale(1);
          }
        }

        &__options {
          display: none;
          position: absolute;
          top: 30px;
          right: 0;
          z-index: 10;
          padding: 10px;
          background-color: #fff;
          border: 1px solid #ccc;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          border-radius: 15px;

          &__item {
            font-size: 16px;
            font-weight: 400;
            white-space: nowrap;
            padding: 5px 10px;
            cursor: pointer;
            border-radius: 15px;
            display: flex;
            align-items: center;
            &.delete {
              color: #DE0303;
            }
            &:hover {
              background-color: #f0f0f0;
            }
          }
        }
      }
    }

    &__time {
      font-size: var(--paragraph-2);
      font-weight: 400;
    }
  }

  &__image {
    width: 100%;
    height: 141px;
    background-color: #f0f0f0;
    border-radius: 8px;
  }


  @media screen and (max-width: 768px){
    min-width: 158px;
    min-height: 170px;


    &__image {
      height: 100px;
    }
  }
}
