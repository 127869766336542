.prompt_map {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__map {
      width: 90%;
      flex-grow: 1;
    }
  }
  