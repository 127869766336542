.button {
  width: 51px;
  aspect-ratio: 1/1;
  border-radius: 15px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  &.blue {
    background-color: var(--blue);
  }

  @media screen and (max-width: 768px) {
    width: 35px;
    border-radius: 12px;
    & svg {
      width: 17px;
    }
  }
}
