.option_tray {
  display: flex;
  gap: 12px;
  padding: 33px 32px;
  padding-bottom: 25px;
  background: #e7f3ff;
  border: 1px solid black;
  border-radius: 25px 25px 0 0;
  border-bottom: none;
  overflow-x: auto;
  max-width: 100vw;

  &.slide-in {
    opacity: 0;
    transform: translateY(100%);
    animation: slideInFromBelow 1s forwards;
  }

  &__button {
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: var(--blue);
    padding: 15px 20px;
    height: fit-content;
    width: fit-content;
    border-radius: 15px;
    border: 1px solid black;
    cursor: pointer;
    &__text {
      font-size: var(--paragraph-1);
      text-align: center;
      color: white;
    }
    &.red {
      background: #ff3333;
    }
    &__img * {
      stroke: white;
    }
  }

  @media screen and (max-width: 768px) {
    gap: 5px;
    padding: 10px;

    &__button {
      padding: 10px;
      &__text {
        font-size: var(--paragraph-2);
      }

      &__img * {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  @media screen and (max-width: 525px){
    border-radius: 0;
    width: 100vw;
    border-left: none;
    border-right: none;
  }
}

@keyframes slideInFromBelow {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
