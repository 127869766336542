.action_node {
    padding: 15px;
    text-align: center;
    background-color: var(--blue);
    width: 190px;
    min-height: 67px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000000;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 6px;
    color: white;
    border-radius: 100%;
  }
  