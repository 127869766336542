.prompt_manager {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__left {
    width: 100%;
    height: 100%;
  }

  &__right {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
  }

  @media screen and (max-width: 500px) {
    &__right {
      width: 100%;
    }
  }
}
