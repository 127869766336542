.map_input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateY(0%);
  transition: transform 0.5s;
  &.hide {
    transform: translateY(70%);
  }
  &.hide &__hide img{
    transform: rotate(180deg);
  }
  &__hide {
    position: absolute;
    top: 0px;
    & img {
      cursor: pointer;
    }
  }

  &__input {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    width: 100%;

    &__change_mode {
      &__button {
        width: 48px;
        aspect-ratio: 1/1;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #000000;
        cursor: pointer;
      }
    }
  }
}
