.profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 36px;
    flex-grow: 1;

    &__header {
      color: var(--blue);
      font-size: var(--page-heading);
      font-weight: 700;
    }

    &__table {
      width: 30%;
      min-width: 320px;
      border: 1px solid #000000;
      border-radius: 15px;
      position: relative;
      background-color: white;

      &__header {
        padding: 20px 0;
        background-color: #e7f3ff;
        border-bottom: 1px solid black;
        border-radius: 15px 15px 0 0;
        font-size: var(--section-heading);
        font-weight: 500;
        color: var(--blue);
        text-align: center;
      }

      &__content {
        padding: 28px 40px;
        &:not(:last-of-type) {
          margin-bottom: 10px;
          border-bottom: 1px solid black;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        &__profile_pic {
          & img {
            border-radius: 100%;
            border: 1px solid black;
            height: 100px;
          }
        }

        &__profile_data {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 8px;
          &__name {
            font-size: var(--refresher-overview-heading);
            font-weight: 500;
            text-align: center;
          }
        }

        &__item {
          display: flex;
          width: 100%;
          &__label {
            width: 50%;
            text-align: left;
          }

          &__value {
            width: 50%;
            text-align: right;
            font-weight: 700;
          }
        }
      }

      &__bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        width: 100%;
      }
    }

    &__logout {
      &__button {
        background-color: var(--yellow);
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
        border: 1px solid black;
      }
    }
  }
}
