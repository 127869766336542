.side_panel_evaluation {
  overflow-y: auto;

  &__title {
    font-size: var(--section-heading);
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__question_box {
    display: flex;
    flex-direction: column;
    gap: 30px;
    &__problem {
      &__question {
        font-size: var(--paragraph-1);
        margin-bottom: 15px;
      }

      &__options {
        display: flex;
        flex-direction: column;
        gap: 10px;
        &__option {
          border: 1px solid #000000;
          border-radius: 11px;
          padding: 15px 20px;
          gap: 14px;
          display: flex;
          cursor: pointer;
          &.correct {
            background: #bdffc0;
          }

          &.wrong {
            background: #ffbdbd;
          }

          &.selected {
            background: var(--yellow);
          }

          &__left {
            display: flex;
            align-items: center;
            justify-content: center;
            &__circle {
              width: 19px;
              height: 19px;
              background: #d9d9d9;
              border-radius: 100%;
            }
          }
          &__right {
            font-size: var(--paragraph-1);
          }
        }
      }
      &__submit {
        width: 100%;
        margin-top: 50px;
        &__status {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.25rem;
          font-size: var(--section-heading);
          font-weight: 700;
          text-align: center;
        }

        &__button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 18px;

          & button {
            display: flex;
            gap: 10px;
            align-items: center;
            background-color: var(--blue);
            padding: 12px 16px;
            height: fit-content;
            width: fit-content;
            border-radius: 15px;
            border: 1px solid black;
            cursor: pointer;
            white-space: nowrap;
            color: white;
            font-size: var(--paragraph-2);

            &:disabled {
              opacity: 0.7;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}
