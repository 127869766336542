.resource_card_expanded {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  &__header {
    display: flex;
    justify-content: space-between;

    &__title {
      font-size: var(--section-heading);
      font-weight: 700;
      margin-bottom: 20px;
    }

    &__close {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;

      & svg {
        font-size: var(--section-heading);
      }
    }
  }

  &__video {
    width: 100%;
    aspect-ratio: 16/9;
  }

  &__data {
    display: flex;
    flex-direction: column;
    gap: 15px;
    &__title {
      font-size: 18px;
      font-weight: 700;
    }

    &__description {
        font-size: var(--paragraph-1);
    }
  }
}
