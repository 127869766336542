.create_map_card {
  cursor: pointer;
  width: 263px;
  min-width: 263px;
  min-height: 227px;
  align-self: stretch;
  border-radius: 15px;
  border: 1px solid black;
  border-bottom: 5px solid black;
  padding: 13px;
  background-color: var(--blue);

  &:hover {
    transform: scale(0.99);
  }

  &__image {
    width: 100%;
    height: 141px;
    background: #339dff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      width: 35px;
      aspect-ratio: 1/1;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: white;
    margin-top: 20px;
  }

  @media screen and (max-width: 768px){
    min-width: 158px;
    max-width: 158px;
    min-height: 170px;


    &__image {
      height: 100px;
    }
  }
}
