.nav_refresher {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 50px;
  display: flex;
  position: relative;

  &::before {
    background: linear-gradient(0deg, #FFFFFF 0%, #F0F0F0 100%);
    content: '';
    width: 100%;
    height: 160px;
    position: absolute;
    z-index: -1;
    top: -20px;
  }

  &__left {
    width: 50%;
  }

  &__right {
    width: 50%;
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: flex-end;
    &__open {
      border-radius: 15px;
      border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 13px 11px;
      padding-right: 17px;
    }

    &__download {
      margin-right: 30px;
      border-radius: 15px;
      border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 13px 11px;
      padding-right: 17px;
      background-color: var(--blue);
      color: white;
    }
  }
}
