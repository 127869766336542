.auth_message {

    &__text {
        padding: 10px 0;
    }

    &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        & img {
            width: 100%;
            max-height: 350px;
        }
    }
}