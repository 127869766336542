.api_key_popup_premium_cta {
  &__header {
    &__line {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #000;
      line-height: 0.1em;
      margin: 10px 0 20px;
      & span {
        background: #fff;
        padding: 0 27px;
        font-size: var(--paragraph-2);
      }
    }
  }
  &__body {
    padding: 0 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    &__subtitle {
      font-size: var(--paragraph-2);
      text-align: center;
      color: #373737;
    }

    &__button {
      background-color: var(--yellow);
      padding: 7px 11px;
      padding-right: 31px;
      border-radius: 8px;
      cursor: pointer;
      border: 1px solid black;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      width: 100%;
      &__icon {
        display: flex;
        align-items: center;
      }

      &__text {
        font-size: var(--paragraph-2);
      }
    }
  }
}
