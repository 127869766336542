.confirmation_popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;

  &__popup {
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 28px);
    max-width: 460px;
    &__top {
      padding: 26px 28px;
      display: flex;
      flex-direction: column;
      border: 1px solid black;
      border-radius: 15px 15px 0 0;
      background: #e7f3ff;
      gap: 20px;
      &__header {
        display: flex;
        justify-content: space-between;

        &__title {
          font-size: var(--section-heading);
          font-weight: 500;
          color: var(--blue);
        }

        &__close {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;

          & svg {
            font-size: var(--section-heading);
          }
        }
      }
    }
    &__middle {
      flex-grow: 1;
      border-left: 1px solid black;
      border-right: 1px solid black;
      padding: 21px 28px;
    }
    &__bottom {
      width: 100%;
      background: #e7f3ff;
      border: 1px solid black;
      border-radius: 0 0 15px 15px;
      display: flex;
      align-items: center;
      padding: 13px 20px;
      gap: 8px;
      justify-content: flex-end;
    }
  }
}
