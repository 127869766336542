.prompt_side_panel {
  width: 430px;
  padding: 24px 0;
  height: calc(100% - 28px);
  background-color: white;
  border-radius: 15px 0 0 15px;
  display: flex;
  flex-direction: column;
  &__top {
    padding: 26px 25px;
    display: flex;
    border: 1px solid black;
    border-radius: 15px 0 0 0;
    border-right: none;
    background: #e7f3ff;
    gap: 20px;
    justify-content: space-between;

    &__title {
      font-size: var(--section-heading);
      font-weight: 700;
      color: var(--blue);
    }

    &__close {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border-radius: 8px;
      border: 1px solid black;
      background-color: white;

      & img {
        width: 17px;
        aspect-ratio: 1/1;
      }
    }
  }
  &__middle {
    flex-grow: 1;
    border-left: 1px solid black;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &__input {
      height: 100%;
      width: 100%;
      padding: 15px 20px;
      border-radius: 11px;

      & textarea::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__bottom {
    border: 1px solid black;
    border-right: none;
    border-radius: 0 0 0 15px;
    padding: 13px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e7f3ff;

    &__button {
      display: flex;
      gap: 7px;
      align-items: center;
      background-color: var(--blue);
      padding: 10px;
      border: 1px solid black;
      height: fit-content;
      width: fit-content;
      border-radius: 8px;
      cursor: pointer;
      font-size: var(--paragraph-1);
      color: white;
    }
  }

  @media screen and (max-width: 500px) {
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 0;
    &__top {
      border-radius: 0;
      padding: 20px 10px;

      &__options__option {
        padding: 7px;
        &__title {
          font-size: 10px;
        }
      }
    }

    &__bottom {
      border-radius: 0;
      padding: 13px 10px;
      max-width: 100vw;
      overflow-x: auto;
      &__button {
        &__text {
          text-wrap: nowrap;
        }
      }
    }
  }
}
