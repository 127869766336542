.nav_map_left {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;

  &.small {
    & button {
      width: 35px;
      border-radius: 12px;
      & svg {
        width: 17px;
      }
    }
  }

  &__options {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__expanded {
    position: relative;
    display: none;
  }

  &.flip img {
    transform: rotate(180deg);
  }

  &.--narrow {
    gap: 8px;
  }

  &.--narrow & {
    &__brand {
      margin-left: 16px;
    }

    &__options {
      display: none;
    }

    &__expanded {
      display: block;
    }
  }
}
