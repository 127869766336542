.navbar_brand {
  display: flex;
  gap: 20px;
  align-items: center;
  width: fit-content;
  padding: 15px 20px;
  padding-right: 40px;
  margin-left: 65px;
  border-radius: 15px;
  cursor: pointer;
  &__small {
    margin-left: 16px;

    & img {
      width: 25px;
    }
  }

  & img {
    width: 130px;
  }
}
