.search_bar_container {
  width: 100%;
  position: relative;
  &__input {
    width: 100%;
    border: 1px solid black;
    padding: 16px 21px;
    padding-right: 18px;
    border-radius: 15px;
    font-size: var(--paragraph-1);
    &:focus {
      outline: none;
    }
  }


  &.small &__input {
    padding: 10px 6px;
    padding-right: 9px;
    border-radius: 10px;
  }

  & img, svg {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.nav_search_bar__button {
  width: 51px;
  aspect-ratio: 1/1;
  border-radius: 15px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
