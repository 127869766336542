.nav_home {
  width: 100%;
  padding-top: 40px;
  display: flex;

  &.blue {
    background: #f5f9ff;
  }
  &__left {
    width: 33%;
    display: flex;
    align-items: center;
  }
  &__middle {
    width: 34%;
    display: flex;
    gap: 18px;
    align-items: center;
    justify-content: center;

    &.hide {
      display: none;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      background-color: transparent;
      padding: 13px 0;
      padding-left: 11px;
      padding-right: 16px;
      border: none;
      cursor: pointer;
      &.active {
        border-radius: 15px;
        border: 1px solid black;
        background-color: var(--yellow);
        border-bottom: 5px solid black;
      }
    }
  }

  &__right {
    width: 33%;
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: flex-end;
    border-radius: 15px;


    &.stretch {
      flex-grow: 1;
    }

    &__settings {
      width: 51px;
      aspect-ratio: 1/1;
      border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__profile {
      cursor: pointer;
      margin-right: 65px;
      width: 51px;
      aspect-ratio: 1 / 1;
      border-radius: 100%;
      background-color: var(--blue);
    }
  }

  @media screen and (max-width: 768px) {
    &__right {
      gap: 9px;
      &__profile {
        width: 35px;
        margin-right: 16px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    &__left {
      width: 30%;
    }
    &__middle {
      position: fixed;
      border: 1px solid black;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      gap: 5px;
      background-color: #f5f9ff;
      padding: 9px 25px;
      border-radius: 20px;
      z-index: 99999999;
      width: fit-content;
      &__button {
        padding: 10px;
        border-radius: 12px;
        & svg {
          width: 17px;
          height: 17px;
        }
      }
    }

    &__right {
      width: 70%;
    }
  }
}
