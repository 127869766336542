.mindmap_page {
  width: 100%;
  height: 100%;
  display: flex;
  &__top_color {
    height: 163px;
    width: 100%;
    position: absolute;
    top: 0;
    background: linear-gradient(0deg, #ffffff 0%, #f0f0f0 100%);
    z-index: -1;
  }
  &__left {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    &__workspace {
      padding: 0 58px;
      width: 100%;
      flex-grow: 1;

      &.side-panel-open {
        padding: 0 10px;
      }
    }
  }

  &__bottom_color {
    height: 163px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, #ffffff 0%, #f0f0f0 100%);
    z-index: -1;
  }
  @media screen and (max-width: 1024px) {
    &__left {
      &__workspace {
        padding: 0 10px;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    &__right {
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
    }
  }

  @media screen and (max-width: 525px) {
    &__left {
      &__workspace {
        padding: 0;
      }
    }
  }
}
