.side_panel_theory,
.side_panel_question,
.side_panel_question * {
  width: 100%;
  line-height: 22px;

  & > *:not(:last-of-type) {
    margin-bottom: 10px;
  }
  .bold {
    font-weight: 700;
  }

  .width {
    width: 100%;
  }

  .list {
    list-style-type: disc;
    margin-left: 20px;

    li {
      margin-bottom: 5px;
    }
  }

  .italic {
    font-style: italic;
  }

  & pre,
  code {
    font-family: monospace;
    color: crimson;
    background-color: #f1f1f1;
    text-wrap: wrap;
  }
}

.side_panel_question {
  &__title {
    font-size: var(--section-heading);
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 1rem;
  }

  &__list {
    text-wrap: wrap;
    &__item {
      &__question {
        font-size: 1.1em;
        font-weight: 700;
        margin-bottom: 20px;
        margin-top: 1rem;
        line-height: 22px;
      }
    }
  }
}
