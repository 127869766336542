.mindmap_page__footer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  &__help {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000080;
    width: 51px;
    height: 51px;
    border-radius: 15px;
    border: 1px solid #00000080;
    color: white;
    position: absolute;
    top: -20px;
    left: 0;
    cursor: pointer;
    z-index: 1000;
  }
  &__left {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__middle {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
  }

  &__right {
    width: 20%;
    height: 100%;
    position: relative;

    &__minimap {
      margin: 0 !important;
      position: absolute !important;
      bottom: 58px !important;
      right: 0px !important;
      width: 220px;
      border-radius: 15px;

      & > svg {
        border-radius: 15px;
        border: 1px solid black;
        width: 220px;
        height: unset;
      }
    }
  }

  &.--narrow & {
    &__right {
      display: none;
    }

    &__middle {
      width: 100%;
    }

    &__left {
      display: none;
    }
  }
}
