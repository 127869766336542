.refresher_map_page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      font-size: var(--page-heading);
      font-weight: 700;
      text-align: center;
      color: var(--blue);
    }

    &__subtitle {
      font-size: var(--paragraph-1);
      font-weight: 400;
      text-align: center;
      margin-top: 9px;
      display: flex;
      gap: 0.5em;
    }
  }

  &__content {
    flex-grow: 1;
    width: 85%;
    &__table {
      margin-top: 28px;
      width: 100%;
      display: flex;

      &__column {
        display: flex;
        flex-direction: column;
        border: 1px solid black;

        &:first-of-type {
          width: 66%;
        }

        &:last-of-type {
          width: 34%;
        }

        &:first-of-type {
          border-radius: 15px 0 0 15px;
        }

        &:not(:first-of-type) {
          border-left: none;
        }
        &:last-of-type {
          border-radius: 0 15px 15px 0;
        }

        &:first-of-type &__header {
          border-radius: 15px 0 0 0;
        }

        &:last-of-type &__header {
          border-radius: 0 15px 0 0;
        }

        &__header {
          padding: 27px 43px;
          padding-right: 0;
          background-color: var(--blue-white);
          border-bottom: 1px solid black;
          font-size: var(--section-heading);
          font-weight: 500;
          color: var(--blue);
        }

        &__content {
          padding: 28px 40px;
          &__theory {
            width: 100%;
            line-height: 1.4;
            .bold {
              font-weight: bold;
            }

            .width {
              width: 100%;
            }

            .list {
              list-style-type: disc;
              margin-left: 20px;

              li {
                margin-bottom: 5px;
              }
            }

            .italic {
              font-style: italic;
            }
          }
        }
      }
    }
  }
}
