.side_panel {
  width: 430px;
  margin: 14px 0;
  height: calc(100% - 28px);
  background-color: white;
  border-radius: 15px 0 0 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  &__top {
    padding: 26px 25px;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    border-radius: 15px 0 0 0;
    border-right: none;
    background: var(--blue-white);
    gap: 20px;
    &__header {
      display: flex;
      justify-content: space-between;

      &__title {
        font-size: var(--section-heading);
        font-weight: 700;
        color: var(--blue);
      }

      &__close {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border-radius: 8px;
        border: 1px solid black;
        background-color: white;

        & img {
          width: 17px;
          aspect-ratio: 1/1;
        }
      }
    }

    &__options {
      display: flex;
      gap: 6px;
      flex-wrap: wrap;

      &__option {
        display: flex;
        gap: 6px;
        align-items: center;
        padding: 7px 0;
        padding-left: 8px;
        padding-right: 17px;
        border-radius: 8px;
        cursor: pointer;

        &.selected {
          border: 1px solid black;
          background-color: var(--yellow);
        }

        &__title {
          font-size: 14px;
        }
      }
    }
  }

  &__middle {
    flex-grow: 1;
    border-left: 1px solid black;
    padding: 21px 28px;
    overflow-y: auto;
  }

  &__bottom {
    width: 100%;
    background: var(--blue-white);
    border: 1px solid black;
    border-radius: 0 0 0 15px;
    display: flex;
    align-items: center;
    padding: 13px 20px;
    gap: 8px;

    &__button {
      display: flex;
      gap: 7px;
      align-items: center;
      background-color: var(--blue);
      padding: 10px 0;
      padding-left: 10px;
      padding-right: 13px;
      border: 1px solid black;
      height: fit-content;
      width: fit-content;
      border-radius: 8px;
      cursor: pointer;

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }

      &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        & svg {
          width: 19px;
          aspect-ratio: 1/1;
        }

        & * {
          stroke: white;
        }
      }

      &__text {
        font-size: var(--paragraph-2);
        text-align: center;
        color: white;
      }
    }
  }

  &__custom_question {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: var(--blue-white);
    border: 1px solid black;
    border-radius: 0 0 0 15px;
    padding: 13px 20px;
    display: flex;
    flex-direction: column;
    &__header {
      font-size: var(--section-heading);
      font-weight: 700;
      display: flex;
      justify-content: space-between;
    }

    &__input {
      flex-grow: 1;
      margin-top: 1rem;
      position: relative;
      background-color: white;
      padding: 15px 10px;
      border-radius: 15px;
      border: 1px solid black;

      & input {
        padding-right: 50px;
      }

      &__send {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        width: 37px;
        aspect-ratio: 1/1;
        background-color: var(--blue);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #000000;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 500px) {
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    &__top {
      border-radius: 0;
      padding: 20px 10px;

      &__options__option {
        padding: 7px;
        &__title {
          font-size: 10px;
        }
      }
    }

    &__bottom {
      border-radius: 0;
      padding: 20px 10px;
      max-width: 100vw;
      overflow-x: auto;
      &__button {
        &__text {
          text-wrap: nowrap;
        }
      }
    }

    &__custom_question {
      border-radius: 0;
    }
  }
}
