.home_page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 120px;
  flex-grow: 1;
  &__file_list {
    min-width: 263px;
    width: 75%;
    margin-top: 120px;

    &__title {
      font-size: var(--section-heading);
      font-weight: 500;
      width: 100%;
      margin-bottom: 18px;
    }

    &__cards {
      display: flex;
      gap: 20px;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
