.delete_node_message {
    &__text {
      font-size: var(--paragraph-1);
      font-weight: 500;
      margin-bottom: 19px;
    }
  
    &__options {
      display: flex;
      flex-direction: column;
      gap: 12px;
  
      &__option {
        padding: 15px 20px;
        border: 1px solid black;
        border-radius: 11px;
        cursor: pointer;
        &.replace_input {
            height: 120px;
        }
  
        &.selected {
          background-color: var(--yellow);
        }
      }
    }
  }
  