.login_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 100%;
    flex-grow: 1;

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__title {
        font-size: 36px;
        font-weight: 700;
        text-align: center;
        color: var(--blue);
      }

      &__text {
        font-size: 24px;
        text-align: center;
      }
    }

    &__login {
      border: 1px solid black;
      background-color: white;
      padding: 40px;
      border-radius: 20px;
      width: 33%;
      display: flex;
      min-width: 300px;
      position: relative;
      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        gap: 0.5rem;
        border: 1px solid black;
        background-color: var(--yellow);
        font-size: var(--paragraph-2);
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 11px;
      }


      &__bg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        width: 100%;
      }
    }
  }
}
