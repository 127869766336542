.input_component {
  width: 100%;
  height: 100%;
  font-size: var(--paragraph-1);
  border: none;
  resize: none;
  &:focus {
    outline: none;
  }

  &[disabled] {
    background-color: white;
  }
}
