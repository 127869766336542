.api_key_estimation {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 28px;
  &__header {
    font-size: var(--paragraph-1);
    font-weight: 500;
  }

  &__table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid black;
    border-radius: 11px;
    overflow: hidden;

    & tr {
      & td:first-child {
        border-top-left-radius: 11px;
      }
      & td:last-child {
        border-top-right-radius: 11px;
        color: var(--blue);
        text-align: center;
        font-weight: 700;
      }
    }

    & td {
      padding: 10px;
      font-size: var(--paragraph-1);
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      padding: 21px 30px;

      &:last-child {
        border-right: none;
      }
    }

    & tr:last-child td {
      border-bottom: none;
    }
  }
}
